import React from "react";
import BotResponse from "./BotResponse";
import logo from "../planorama-logo.svg";

const IntroSection = ({greeting}) => {
  return (
    <div id="introsection">
      <img src={logo} className="logo" />

      <h1><BotResponse response={greeting} firstMessage={true}/></h1>
    </div>
  );
};

export default IntroSection;
