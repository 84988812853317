import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const Loading = () => {
  const override = {
    color: "#fff",
    loading: true,
  };

  return (
    <div className="loading-spinner">
      <PulseLoader
        loading={override.loading}
        cssOverride={override}
        size={5}
        aria-label="Loading Spinner"
        data-testid="loader"
        className="dots-wrapper" 
      />
    </div>
  );
};

export default Loading;
