import "./normal.css";
import ChatHome from "./pages/ChatHome";
import ChatHomeStream from "./pages/ChatHomeStream";
import ChatHomeSinfonia from "./pages/ChatHomeSinfonia";
import ChatHomeExternal from "./pages/ChatHomeExternal";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PDFViewer from "./components/PDFViewer";

function App() {
  // const { currentUser } = useContext(AuthContext);
  // const RequireAuth = ({ children }) => {
  //   return currentUser ? children : <Navigate to="auth/login" />;
  // };

  return (
    <Routes>
      <Route
        index
        exact
        path="/projects"
        element={
          // <RequireAuth>
          <Home />
          // </RequireAuth> 
        }
      />
      <Route
        index
        exact
        path="/marketing"
        element={
          <ChatHome url="https://dev-api.textbooks.live/mkt" title='Principles of Marketing' greeting='How can I help you today?' projectAlias='marketing'/>
        }
        
      />
      <Route
        index
        exact
        path="/management"
        element={
          <ChatHome url="https://dev-api.textbooks.live/mgm" title='Principles of Management' greeting='How can I help you today?' projectAlias='management'/>
        }
      />
      <Route
        index
        exact
        path="/powerpc"
        element={
          <ChatHome url="https://dev-api.textbooks.live/mpc" title='PowerPC 603e Processor' greeting='How can I help you today?' projectAlias='powerpc'/>
        }
      />
      <Route
        index
        exact
        path="/washer-dryer"
        element={
          <ChatHome url="https://dev-api.textbooks.live/swd" title='Samsung Washer Dryer Manual' greeting='How can I help you today?' projectAlias='washer-dryer'/>
        }
      />
      <Route
        index
        exact
        path="/risc-v"
        element={
          <ChatHome url="https://dev-api.textbooks.live/code" title='RISC-V' greeting='How can I help you today?' projectAlias='risc-v'/>
        }
      />
      <Route
        index
        exact
        path="/Sinfonia"
        element={
          // <ChatHomeSinfonia url='http://localhost:5001/ask-product' />
          <ChatHomeSinfonia url='https://dev-api.textbooks.live/ask-product' greeting='How can I help you today?'/>
        }
      />
      <Route
        index
        exact
        path="/oi-eval"
        element={
          // <RequireAuth>
            <ChatHomeStream url='https://dev-api.textbooks.live/oi-eval' greeting='Hello! Please ask a question about the manuals, guarantees and other documents'/>
          // </RequireAuth> 
        }
      />
      <Route
        index
        exact
        path="/external-products"
        element={
          <ChatHomeExternal url='https://dev-api.textbooks.live/ask-external-product' title='Vida B2C' greeting='How can I help you today?' projectAlias='' productId='1652c8ee-cfe9-455f-8cdf-fbd2f7d6a442'/>
        }
      />
      <Route
        index
        exact
        path="/file/:project/:file/:startPage?"
        element={
          <PDFViewer/>
        }
      />
    </Routes>
  );
}

export default App;
