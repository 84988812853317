import React, { useEffect } from "react";
import { useState } from "react";
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

const BotResponse = ({ response, sources, chatLogRef, answersBottomRef, firstMessage }) => {
  const [botResponse, setBotResponse] = useState("");
  const [botSources, setBotSources] = useState("");
  const [isPrinting, setIsPrinting] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    let index = 1;
    let msg = setInterval(() => {
      if (firstMessage) {
        setIsButtonVisible(true);
      }
      if (!isPrinting) {
        // if isPrinting is false, clear interval and return
        clearInterval(msg);
        return;
      }
      setBotResponse(response.slice(0, index));
      if (index >= response.length) {
        let lenght = response.length;
        if (sources) {
          setBotSources(sources.slice(0, index));
          lenght += sources.length;
        }

        if (index >= lenght) {
          clearInterval(msg);
          setIsButtonVisible(false);
        }
      }
      index++;

      answersBottomRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 0);
    return () => clearInterval(msg); // clear interval on component unmount
  }, [answersBottomRef, response, sources, isPrinting]);

  const stopPrinting = () => setIsPrinting(!isPrinting);

  return (
    <>
      {/* <pre> */}
      <ReactMarkdown remarkPlugins={[gfm]}>
        {botResponse}
      </ReactMarkdown>
      {botSources && botSources.length > 0 && (
        botSources.split('\n').map((text, index) => (
          <React.Fragment key={'source' + index}>
            {parse(text)}
            {index !== botSources.length - 1 ? <br /> : null}
          </React.Fragment>
        ))
      )}
      {/* </pre> */}
      {isButtonVisible && (
        <button className="stop-messgage" onClick={stopPrinting}>
          {isPrinting ? "Stop Message" : "Regenerate Message"}
        </button>
      )}
    </>
  );
};

export default BotResponse;
