import React from 'react';
import { useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const PDFViewer = () => {
  const { project, file, startPage } = useParams();
  const pdfURL = `/${project}/${file}.pdf`;
  const [page, setPage] = useState("");
  const [numPages, setNumPages] = useState(0);
  const pageRefs = useRef({});

  const onItemClick = () => {
    pageRefs.current[page].scrollIntoView({ behavior: 'smooth' });
  }

  const handleInput = (e) => {
    setPage(e.target.value);
  };

  function handleKeyDown(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      onItemClick();
    }
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function makeOnAllPagesRenderedCallback(onAllPagesRendered) {
    const pages = [];
  
    function ref(pageComponent) {
      if (!pageComponent) {
        return;
      }
  
      pages[pageComponent.pageIndex] = false;
    }
  
    function onRenderSuccess(page) {
      pages[page.pageIndex] = true;

      if (pages.every(Boolean)) {
        onAllPagesRendered();
      }
    }
  
    return {
      ref,
      onRenderSuccess,
    };
  }
  
  function onAllPagesRendered() {
    if (startPage > 0 && startPage <= numPages)
    {
      pageRefs.current[startPage].scrollIntoView();
    }
  }

  const additionalProps = makeOnAllPagesRenderedCallback(onAllPagesRendered);

  return (
    <div>
      <div style={{zIndex: 0, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Document
          file={pdfURL}
          onLoadSuccess={onDocumentLoadSuccess}>
          {Array(...Array(numPages))
            .map((x, i) => i + 1)
            .map(page => (
              <div key={page} ref={el => { pageRefs.current[page] = el; }}>
                <Page scale={1.5}
                  pageNumber={page}
                  loading=""
                  {...additionalProps}
                />
              </div>
            ))}
        </Document>
      </div>
      <div style={{position: 'sticky', bottom: 0, zIndex: 1, backgroundColor: '#282c34', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <input
          type="text"
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          width={75}
          style={{margin:'10px', width: '75px', fontSize: '14px'}} />
        <button onClick={onItemClick}>Go to page</button>
      </div>
    </div>
  );
};

export default PDFViewer;