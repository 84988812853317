import React, { useEffect, useRef, useState, createRef } from "react";
import Avatar from "../components/Avatar";
import Error from "../components/Error";
import IntroSection from "../components/IntroSection";
import Loading from "../components/Loading";
import logo from "../planorama-icon.svg";
import parse from 'html-react-parser';

function generateRandomId() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

const ChatHomeStream = ({ url, greeting }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setReponseFromAPI] = useState(false);
  const [id, setId] = useState("");
  const [isStartOverButtonVisible, setIsStartOverButtonVisible] = useState(false);
  const [modal, setModal] = useState(false);

  const chatLogRefs = useRef([]);
  const answersBottomRef = useRef();

  const refreshId = () => {
    const existingId = sessionStorage.getItem("id");
    
    if (existingId && existingId !== 'undefined') {
      setId(existingId);
    } else {
      // generate a new ID
      const newId = generateRandomId();
      setId(newId);
    
      // save new ID to local storage
      sessionStorage.setItem("id", newId);
    }
  }

  const handleInput = (e) => {
    setInputPrompt(e.target.value);
    // e.target.style.height = "auto";
    // e.target.style.height = e.target.scrollHeight + "px";
  };

  function handleKeyDown(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {
        setIsStartOverButtonVisible(true);
        // Set responseFromAPI to true before making the fetch request
        setReponseFromAPI(true);
        setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
        callAPI();

        // hide the keyboard in mobile devices
        if (e) e.target.blur();
        //e.target.style.height = "46px";
      }

      async function callAPI() {
        try {

          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Bypass-Tunnel-Reminder": "true",
              Authorization:
                "Basic " +
                btoa(
                  localStorage.getItem("user") +
                  ":" +
                  localStorage.getItem("password")
                ),
            },
            body: JSON.stringify({ session_id: id, query: inputPrompt }),
          });

          if (response.status === 401) {
            clearLocalStorage();
          }

          let answer = ''
          // Read the response as a stream of data
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            let chunk = decoder.decode(value);
            chunk = chunk.replaceAll('data: ', '')
            chunk = chunk.replaceAll('File: ', '')

            answer = answer.concat(chunk)
            setChatLog([
              ...chatLog,
              {
                chatPrompt: inputPrompt,
                botMessage: answer,
              },
            ]);
          }
          setErr(false);
        } catch (err) {
          setErr(err);
          console.log(err);
        }
        //  Set responseFromAPI back to false after the fetch request is complete
        setReponseFromAPI(false);
      }
    }

    setInputPrompt("");
  };

  function clearLocalStorage() {
    localStorage.removeItem("user");
    localStorage.removeItem("password");
    localStorage.removeItem("saved_password");

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    chatLogRefs.current = Array(chatLog.length)
      .fill()
      .map((_, idx) => chatLogRefs.current[idx] || createRef());
    answersBottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatLog]);

  useEffect(() => {
    refreshId();
    return () => { };
  }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  const submitFromModal = () => {
    handleSubmit();
    toggleModal();
  }

  const closeModal = () => {
    setInputPrompt("");
    setModal(!modal);
  }

  return (
    <>
      <header>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@500;600;700&display=swap');
        </style>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        
        { isStartOverButtonVisible ?
        <button
          id="expandedRefreshButton"
          onClick={() => {
            setIsStartOverButtonVisible(false);
            sessionStorage.setItem("id", undefined);
            refreshId()
            setChatLog([])
            if (modal) toggleModal();
          }}>
          <span className="material-symbols-outlined">refresh</span>Start Over
        </button> : null}
        { isStartOverButtonVisible ? 
        <button
          id="minimizedRefreshButton"
          title="Start over"
          onClick={() => {
            setIsStartOverButtonVisible(false);
            sessionStorage.setItem("id", undefined);
            refreshId()
            setChatLog([])
            if (modal) toggleModal();
          }}>
          <span className="material-symbols-outlined">refresh</span>
        </button> : null}
        
        <h1><span className="titlePrefix">Go Ask </span>Ida</h1>
      </header>

      <div>
        <section className="chatBox">
          {chatLog.length > 0 ? (
            chatLog.map((chat, idx) => (
              <div
                className="chatLog"
                key={idx}
                ref={chatLogRefs.current[idx]}
                id={`navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, "-")}`}
              >
                <div className="chatPromptMainContainer">
                  <div className="chatPromptWrapper">
                  <Avatar bg="#0563C1" className="userSVG">
                      <svg
                        stroke="white"
                        fill="none"
                        strokeWidth={1.9}
                        viewBox="0 0 24 24"
                        // strokeLinecap="round"
                        // strokeLinejoin="round"
                        className="h-6 w-6"
                        height={40}
                        width={40}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                    </Avatar>
                    <div id="chatPrompt">{chat.chatPrompt}</div>
                  </div>
                </div>

                <div className="botMessageMainContainer">
                  <div className="botMessageWrapper">
                    <Avatar bg="transparent" >
                      <img src={logo} alt="avatar" width={44} height={44} className="messageLogo"/>
                    </Avatar>
                    {chat.botMessage ? (
                      <div id="botMessage">      {chat.botMessage.split('\n').map((text, index) => (
                        <React.Fragment key={index}>
                          {parse(text)}
                          {index !== chat.botMessage.length - 1 ? <br /> : null}
                        </React.Fragment>
                      ))}</div>
                    ) : err ? (
                      <Error err={err} />
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <IntroSection greeting={greeting}/>
          )}
        </section>
      </div>


      <div className="footer">
        <form id="form" onSubmit={handleSubmit} className="inputPromptWrapper">
          <div>
            <button onClick={toggleModal}>
              <span className="material-symbols-outlined">
                article
              </span>
            </button>
            <textarea
              name="inputPrompt"
              className="inputPromptTextArea"
              form="form"
              value={inputPrompt}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              autoFocus
          ></textarea>
            <button aria-label="form submit" type="submit">
              <span className="material-symbols-outlined">send</span>
            </button>
          </div>
        </form>
      </div>

      <div ref={answersBottomRef}></div>

      {modal && (
        <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="myModalLabel">How can I help you today?</h3>
              </div>
              <div className="modal-body">
                <textarea id="textareaID" onInput={handleInput} className="form-control" rows={7} cols={50}></textarea>
              </div>
              <div className="modal-footer">
                <button type="button" className="close-modal" onClick={() => closeModal()}>X</button>
                <button type="button" className="modalButton" onClick={() => submitFromModal()}>
                  <span className="material-symbols-outlined">send</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatHomeStream;
